import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAssetPath } from '../utils';

function MenuPage() {
  const navigate = useNavigate();

  // Function to handle the navigation with the current date
  const handleViewWinnerClick = () => {
    const currentDate = new Date().toISOString().split('T')[0]; // Gets the current date in YYYY-MM-DD format
    navigate(`/winner/${currentDate}`);
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
      <img
        src="assets/cc background heart.svg"
        className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
        style={{ width: '1468px', zIndex: 0 }}
      />
      <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
        <Link to="/" className='cc-button-secondary absolute top-6 left-6 flex items-center justify-center' style={{ fontSize: 28, width: '57px', height: '44px', zIndex: 10 }}>
          <img
            src={getAssetPath('assets/back arrow.svg')}
            alt="POWRÓT"
            style={{ width: '25px', height: '28px', maxWidth: 'unset' }}
          />
        </Link>
        <div className="relative">
          <img
            src={getAssetPath('assets/heart.svg')}
            className="w-full"
            style={{ minHeight: '330px' }}
          />
          <img
            src={getAssetPath('assets/cc hero.png')}
            className="absolute top-[0] left-1/2 transform -translate-x-1/2 w-[74%]"
            style={{ marginLeft: '-12px' }}
          />
          {/* Bubbles */}
          <img
            src='/assets/bubbles 4.svg'
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '360px', left: '15px', transform: 'scale(1.1)' }}
          />
          <img
            src='/assets/bubbles 2.svg'
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '170px', right: '90px', transform: 'scale(1.1) rotate(-15deg)' }}
          />
          <img
            src='/assets/bubbles 5.svg'
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '80px', left: '100px', transform: 'scale(1.25)' }}
          />
          <img
            src='/assets/bubbles 3.svg'
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '600px', right: '8px', transform: 'scale(1.1)' }}
          />
        </div>
        <div className='max-w-xs relative mx-auto px-2' style={{ marginTop: '15px' }}>



          <img
            src={getAssetPath('assets/cc besties forever.svg')}
            alt="BESTIES FOR A LIMITED TIME"
            className="w-full"
            style={{ transform: 'scale(0.95)', minHeight: '62px' }}
          />
          <Link to="/" className="cc-button-secondary w-full cc-font-bold text-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block", padding: "2px 0" }}>
            ZAGRAJ
          </Link>
          <button onClick={handleViewWinnerClick} className="cc-button-secondary mt-2 w-full cc-font-bold text-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block", padding: "2px 0" }}>
            RANKING
          </button>
          <Link to="/highscores" className="cc-button-secondary mt-2 w-full cc-font-bold text-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block", padding: "2px 0" }}>
            ZOBACZ KTO WYGRAŁ
          </Link>
          <Link to="/terms" className="cc-button-secondary mt-2 w-full cc-font-bold text-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block", padding: "2px 0" }}>
            REGULAMIN
          </Link>
          <Link to="/contact" className="cc-button-secondary mt-2 w-full cc-font-bold text-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block", padding: "2px 0" }}>
            KONTAKT
          </Link>
          <Link to="https://cokeurl.com/F02F87" className="cc-button-secondary mt-2 mb-8 w-full cc-font-bold text-center flex items-center justify-center" style={{ fontSize: "28px", lineHeight: "40.68px", display: "block" }}>
            <img
              src='assets/coca-cola app.svg'
              alt="Coca Cola & Oreo"
              className="mr-2, display: inline-block"
              style={{ width: '25.45px', height: '34.2px', marginTop: '-8px', marginRight: '10px' }}
            />
            COCA-COLA APP
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MenuPage;
