import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterPage from './components/RegisterPage';
import WelcomePage from './components/WelcomePage';
import MenuPage from './components/MenuPage';
import GamePage from './components/GamePage';
import ContactPage from './components/ContactPage';
import TermsPage from './components/TermsPage';
import HighscoresListPage from './components/HighscoresListPage';
import HighscorePage from './components/HighscorePage';
import GameStatusPage from './components/GameStatusPage';
import Modal from 'react-modal';

// Set the app element to hide everything outside of the modal
Modal.setAppElement('#root');

function App() {
  return (
    <Router basename='/'>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/game" element={<GamePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/highscores" element={<HighscoresListPage />} />
        <Route path="/highscores/:date" element={<HighscorePage viewType="view-date" />} />
        <Route path="/check-result/:date" element={<HighscorePage viewType="check-result" />} />
        <Route path="/winner/:date" element={<HighscorePage viewType="view-winner" />} />
        <Route path="/game-status/:viewMode" element={<GameStatusPage />} />
        <Route path="*" element={<WelcomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
