import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import { getAssetPath } from '../utils';
import CustomModal from './CustomModal';
// import useGyroscope from '../gyroscope';

function WelcomePage() {
  const { setCode, setReceivedData } = useContext(GlobalContext);
  const [inputCode, setInputCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const navigate = useNavigate();

  // Use the gyroscope hook
  // const { x, y, z } = useGyroscope({ frequency: 60 });

  const handleInputChange = (e) => {
    setInputCode(e.target.value);
  };

  const handleClick = async () => {
    if (inputCode.trim() === '') return;

    try {
      // Ensure gyroscope values are available before making the API request
      // if (window.location.hostname !== 'localhost') {
      //   let retries = 2; // Retry up to 2 times
      //   while ((x === null || y === null || z === null) && retries > 0) {
      //     console.log('Waiting for gyroscope values...');
      //     await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 100 ms
      //     retries -= 1;
      //   }

      //   if (x === null && y === null && z === null) {
      //     throw new Error('NO_GYROSCOPE');
      //   }
      // }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ code: inputCode }),
      });

      const result = await response.json();

      // Handle different status codes
      if (response.ok) {
        setCode(inputCode);
        setReceivedData(result.data);
        navigate('/register');
      } else if (response.status === 422) {
        // Validation error: Code too short or not valid
        const errorMessage = result.errors?.code?.[0] || 'Invalid code';
        throw new Error(errorMessage);
      } else if (response.status === 400) {
        // Code already used
        throw new Error('code.used');
      } else if (response.status === 404) {
        // Code not found in the database
        throw new Error('code.valid');
      } else {
        // Generic error handling
        throw new Error('unknown');
      }
    } catch (error) {
      console.log('Error caught:', error.message);
      handleError(error.message);
    }
  };

  const handleError = (message) => {
    switch (message) {
      case 'NO_GYROSCOPE':
        setModalData({
          iconSrc: '/assets/alert 2.svg',
          title: 'BRAK ŻYROSKOPU',
          description: 'Do poprawnego działania aplikacji potrzebny jest żyroskop, którego Twoje urządzenie nie posiada. Bez niego rozgrywka jest niemożliwa.',
          buttonText: 'POWRÓT'
        });
        break;
      case 'The code must be 8 characters.':
        setModalData({
          iconSrc: '/assets/alert 1.svg',
          title: 'NIEPOPRAWNY FORMAT KODU',
          description: 'Kod musi mieć 8 znaków. Spróbuj ponownie.',
          buttonText: 'POWRÓT'
        });
        break;
      case 'code.used':
        setModalData({
          iconSrc: '/assets/alert 1.svg',
          title: 'KOD WYKORZYSTANY',
          description: 'Ten kod został już wykorzystany. Użyj nowego kodu.',
          buttonText: 'POWRÓT'
        });
        break;
      case 'code.valid':
        setModalData({
          iconSrc: '/assets/alert 1.svg',
          title: 'NIEPOPRAWNY KOD',
          description: 'Kod jest niepoprawny. Spróbuj ponownie.',
          buttonText: 'POWRÓT'
        });
        break;
      default:
        setModalData({
          iconSrc: '/assets/alert 1.svg',
          title: 'BŁĄD',
          description: 'Wystąpił nieznany błąd. Spróbuj ponownie później.',
          buttonText: 'POWRÓT'
        });
        break;
    }
    setIsModalOpen(true);
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
      <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
        <Link to="/menu" className='cc-button-secondary absolute z-10 top-6 left-6 flex items-center justify-center text-black text-xl font-bold mr-2 cc-font-bold' style={{ width: '121px' }}>
          <img
            src={getAssetPath('assets/cc logo.svg')}
            alt="Coca Cola & Oreo"
            className="mr-2" style={{ width: '35px' }}
          />
          MENU
        </Link>
        <div className="relative" style={{ minHeight: '343px' }}>
          <img
            src={getAssetPath('assets/cc hero background.svg')}
            className="w-full"
          />
          <img
            src={getAssetPath('assets/cc hero.png')}
            className="absolute top-[27px] left-1/2 transform -translate-x-1/2 w-[85%]"
            style={{ marginLeft: '-12px', minHeight: '343px' }}
          />
        </div>
        <div className='max-w-xs relative mx-auto px-4'>
          <img
            src={getAssetPath('assets/cc besties forever.svg')}
            alt="BESTIES FOR A LIMITED TIME"
            className="w-full transform scale-150"
            style={{ minHeight: '62px' }}
          />
          <h1 className="text-2xl text-center cc-font-bold mt-2" style={{ fontSize: 36, minHeight: '32px' }}>
            Zapraszamy do zabawy
          </h1>
          <p className="block text-xs text-center text-gray-700 mt-3 cc-font-light" style={{ fontSize: "16px", minHeight: '71px' }}>
            Aby wejść do gry, wpisz kod, który znajdziesz w aplikacji
            <img
              src={getAssetPath('assets/zappka.svg')}
              alt="żappka"
              className='mx-auto mt-3'
              style={{ width: '71px', minHeight: '27px' }}
            />
          </p>
          <input
            type="text"
            placeholder="WPISZ KOD"
            className="cc-input mt-6 w-full cc-font-bold"
            value={inputCode}
            onChange={handleInputChange}
            required
          />
          <button
            type="button"
            onClick={handleClick}
            className="cc-button-primary mt-3 w-full text-white text-xl font-bold cc-font-bold block"
            style={{ fontSize: 28 }}
            disabled={!inputCode.trim()}
          >
            DALEJ
          </button>
        </div>
        <div className='cc-font-bold cc-legal-text mt-16 text-center text-xs ml-6 mr-6 mb-4'>
          <p>
            © 2024 The Coca-Cola Company. COCA-COLA jest zastrzeżonym znakiem towarowym The Coca-Cola Company.
          </p>
          <p>
            OREO, the OREO Wafer Design, and OREO Design are trademarks of Mondelēz International group, used under license.
          </p>
        </div>
      </div>

      {/* Modal Component */}
      <CustomModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={modalData.title}
        iconSrc={modalData.iconSrc}
        title={modalData.title}
        description={modalData.description}
        buttonText={modalData.buttonText}
      />
    </div>
  );
}

export default WelcomePage;
