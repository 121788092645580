import React from 'react';
import Modal from 'react-modal';
import '../styles/Modal.css';

const CustomModal = ({ 
    isOpen, 
    onRequestClose, 
    contentLabel, 
    iconSrc, 
    title, 
    description, 
    buttonText 
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={contentLabel}
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="modal-content">
                {iconSrc && <img src={iconSrc} alt="Icon" className="modal-icon" />}
                <div className='modal-title cc-font-bold'>{title}</div>
                <div className='modal-text cc-font-bold'>{description}</div>
                <button 
                    className='cc-button-primary cc-font-bold w-full' 
                    onClick={onRequestClose}
                >
                    {buttonText}
                </button>
            </div>
        </Modal>
    );
};

export default CustomModal;
