import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';

function GamePage() {
  const { code, playerId, accessToken, setReceivedData, clearData } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Function to detect if the browser is Safari
  function IsSafari() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }

  useEffect(() => {
    // Check if any of the required data is null and redirect to home page if so
    if (!code || !playerId || !accessToken) {
      navigate('/');
      return;
    }

    // Function to handle messages from the Unity iframe
    const handleMessage = (event) => {
      // Ensure you only accept messages from the trusted origin
      // Uncomment the following line to restrict to a specific origin
      // if (event.origin !== "https://magic-share.com") return;

      let data = event.data;

      console.log("Data received from Unity:", data);

      // parse data json from string
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log("Parsed data:", data);
        } catch (e) {
          console.error("Failed to parse data:", e);
          return;
        }
      }

      if (data.action === "requestUserData") {
        // Respond with user data when requested by Unity
        const userData = {
          code: code,
          access_token: accessToken,
          player_id: playerId,
          safari: IsSafari()
        };

        console.log("Sending user data to Unity:", userData);
        event.source.postMessage({ action: "sendUserData", userData: userData }, event.origin);
      }
      else if (data.action === "gameOver") {
        console.log("Game Over! Data from Unity:", data.gameData);
        setReceivedData(data.gameData);
        navigate('/game-status/game-result');
        // clear data
        clearData();
      }
    };

    // Attach the event listener
    window.addEventListener("message", handleMessage, false);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [code, playerId, accessToken, clearData, navigate]);

  useEffect(() => {
    const iframe = document.querySelector('iframe');

    window.scrollTo(0, 0);

    const requestFullscreen = () => {
      // Reset scroll position to top
      window.scrollTo(0, 0);

      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { // IE/Edge
        iframe.msRequestFullscreen();
      }
    };

    // Trigger fullscreen on iframe click
    iframe.addEventListener('click', requestFullscreen);

    // Cleanup the event listener on component unmount
    return () => {
      iframe.removeEventListener('click', requestFullscreen);
    };
  }, []);

  return (
    <iframe
      src="https://thearworlds.com/unity-app"
      title="Game"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; camera; microphone; geolocation; magnetometer; fullscreen"
      allowFullScreen
      style={{ width: '100vw', height: '100vh', border: 'none', display: 'block', margin: '0', padding: '0' }}
    ></iframe>
  );
}

export default GamePage;
