import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../GlobalContext';
import { getAssetPath } from '../utils';
import CustomModal from './CustomModal';

function RegisterPage() {
  const navigate = useNavigate();
  const { code, setPlayerId, setAccessToken, clearData } = useContext(GlobalContext);

  const beginButtonRef = useRef(null);

  useEffect(() => {
    if (!code) {
      navigate('/');
    }
  }, [code, navigate]);

  const initialFormState = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    terms_agreement: false,
    rodo_agreement: false,
    code: code || '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedData = localStorage.getItem('registerFormData');
    if (savedData) {
      let formData = JSON.parse(savedData);
      formData.code = code;
      setFormData(formData);

      if (beginButtonRef.current) {
        beginButtonRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [code]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
    setFormData(updatedFormData);

    localStorage.setItem('registerFormData', JSON.stringify(updatedFormData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        code: formData.code,
        email: formData.email,
        phone: formData.phone,
        terms_agreement: formData.terms_agreement ? 1 : 0,
        rodo_agreement: formData.rodo_agreement ? 1 : 0,
      };

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      });

      const result = await response.json();

      if (!response.ok) {
        if (response.status === 422) {
          const errors = result.errors;
          let errorMessages = '';
          if (errors.firstname) errorMessages += 'Pole imię jest wymagane. ';
          if (errors.lastname) errorMessages += 'Pole nazwisko jest wymagane. ';
          if (errors.email) errorMessages += 'Uzupełnij poprawny adres e-mail. ';
          if (errors.phone) errorMessages += 'Podaj poprawny numer telefonu. ';
          if (errors.code) errorMessages += 'Podany kod nie istnieje. ';
          throw new Error(errorMessages || 'Nieprawidłowe dane.');
        }
        if (response.status === 404) {
          navigate('/game-status/before-game');
        }
        throw new Error(result.message || 'Wystąpił nieznany błąd');
      } else {
        setPlayerId(result.player_id);
        setAccessToken(result.access_token);
        navigate('/game-status/start-game');
      }
    } catch (error) {
      setModalData({
        iconSrc: '/assets/alert 1.svg',
        title: 'BŁĄD',
        description: error.message,
        buttonText: 'POWRÓT',
      });
      setIsModalOpen(true);
    }
  };

  const handleClear = () => {
    setFormData(initialFormState);
    clearData();
    localStorage.removeItem('registerFormData');
  };

  const isFormFilled = () => {
    return Object.values(formData).every((field) => {
      if (typeof field === 'boolean') return field === true;
      return field.trim() !== '';
    });
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
      <img
        src="assets/cc background heart.svg"
        className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
        style={{ width: '1468px', zIndex: 0 }}
      />
      <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
        <Link to="/menu" className="cc-button-secondary absolute z-10 top-6 left-6 flex items-center justify-center text-black text-xl font-bold mr-2 cc-font-bold" style={{ width: '121px' }}>
          <img
            src={getAssetPath('assets/cc logo.svg')}
            alt="Coca Cola & Oreo"
            className="mr-2"
            style={{ width: '35px' }}
          />
          MENU
        </Link>

        {isFormFilled() && (
          <div className="cc-button-secondary absolute z-10 top-6 right-5 flex items-center justify-center" style={{ width: '90px', padding: '12px', lineHeight: 1 }}>
            <button onClick={handleClear} className="cc-font-bold" style={{ fontSize: '25px' }}>
              WYCZYŚĆ
            </button>
          </div>
        )}

        <div className="relative">
          <img
            src={getAssetPath('assets/cc logo top.svg')}
            className="w-full w-[100%]"
            style={{ minHeight: '314px' }}
          />
        </div>

        <form className="max-w-xs relative mx-auto" onSubmit={handleSubmit}>
          <img
            src={getAssetPath('assets/bubbles 2.svg')}
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '-50px', right: '0px', transform: 'scale(1.1)' }}
          />
          <img
            src={getAssetPath('assets/bubbles 1.svg')}
            alt=""
            className="absolute z-0"
            style={{ width: '30px', height: 'auto', top: '-95px', left: '0px', transform: 'scale(1.7)' }}
          />

          <img
            src={getAssetPath('assets/cc besties forever.svg')}
            alt="BESTIES FOR A LIMITED TIME"
            className="w-full mt-2"
          />
          <div className="text-center cc-font-bold" style={{ fontSize: 24, marginTop: '-20px' }}>
            Poznajmy się
          </div>

          <input
            type="text"
            name="firstname"
            placeholder="IMIĘ"
            className="cc-input w-full cc-font-bold"
            value={formData.firstname}
            onChange={handleChange}
            required
            autoComplete="given-name"
          />
          <input
            type="text"
            name="lastname"
            placeholder="NAZWISKO"
            className="cc-input mt-2 w-full cc-font-bold"
            value={formData.lastname}
            onChange={handleChange}
            required
            autoComplete="family-name"
          />
          <input
            type="email"
            name="email"
            placeholder="E-MAIL"
            className="cc-input mt-2 w-full cc-font-bold"
            value={formData.email}
            onChange={handleChange}
            required
            autoComplete="email"
          />
          <input
            type="tel"
            name="phone"
            placeholder="TELEFON"
            className="cc-input mt-2 w-full cc-font-bold"
            value={formData.phone}
            onChange={handleChange}
            required
            pattern=".{9,}"
            autoComplete="tel"
          />
          <input
            type="hidden"
            name="code"
            value={formData.code}
          />
          <div className="cc-checkbox-group mt-2">
            <input type="checkbox" id="terms" name="terms_agreement" checked={formData.terms_agreement} onChange={handleChange} required />
            <label htmlFor="terms" className="cc-font-light">
              Potwierdzam, że zapoznałem/am się z Regulaminem i akceptuję wszystkie zawarte w nim warunki.
            </label>
          </div>
          <div className="cc-checkbox-group mt-2">
            <input type="checkbox" id="rodo" name="rodo_agreement" checked={formData.rodo_agreement} onChange={handleChange} required />
            <label htmlFor="rodo" className="cc-font-light">
              Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu w celu przeprowadzenia, rozstrzygnięcia konkursu oraz komunikacji Organizatora z Uczestnikiem.
            </label>
          </div>
          <button
            type="submit"
            className="cc-button-secondary mt-4 mb-8 w-full cc-font-bold"
            style={{ fontSize: "36px", lineHeight: "40.68px" }}
            ref={beginButtonRef}
          >
            ZACZNIJ ZABAWĘ
          </button>
        </form>
      </div>

      {/* Modal Component */}
      <CustomModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={modalData.title}
        iconSrc={modalData.iconSrc}
        title={modalData.title}
        description={modalData.description}
        buttonText={modalData.buttonText}
      />
    </div>
  );
}

export default RegisterPage;
