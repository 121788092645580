import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function HighscoresListPage() {
    const [dates, setDates] = useState([]);
    const [errorMessage, setErrorMessage] = useState(''); // State to handle error messages

    // Function to fetch dates from API using the game ID
    const fetchDates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/ranking`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch dates');
            }

            const data = await response.json();
            return data.map(obj => obj.date);
        } catch (error) {
            console.error(error);
            setErrorMessage(error.message);
            return [];
        }
    };

    useEffect(() => {
        const fetchAndSetData = async () => {
            const fetchedDates = await fetchDates();
                const formattedDates = fetchedDates.map((dateStr) => {
                    const date = new Date(dateStr);
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    return {
                        displayDate: `${day}.${month}.${year}`, // For display in the UI
                        apiDate: `${year}-${month}-${day}`       // For API URL
                    };
                });
                setDates(formattedDates);
        };

        fetchAndSetData();
    }, []);

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
            <img
                src="assets/cc background heart.svg"
                className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
                style={{ width: '1468px', zIndex: 0 }}
            />
            <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
                <Link to="/menu" className='cc-button-secondary absolute top-6 left-6 flex items-center justify-center' style={{ fontSize: 28, width: '57px', height: '44px', zIndex: 10 }}>
                    <img
                        src="/assets/back arrow.svg"
                        alt="POWRÓT"
                        style={{ width: '25px', height: '28px', maxWidth: 'unset' }}
                    />
                </Link>
                <div className="relative max-w-xs mx-auto px-4 mt-24">

                    {/* Bubbles */}
                    <img
                        src='/assets/bubbles 3.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '0px', right: '-25px', transform: 'scale(1.1) ' }}
                    />
                    <img
                        src='/assets/bubbles 3.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '545px', left: '-25px', transform: 'scale(1.1) ' }}
                    />

                    <div className="text-2xl text-center cc-font-bold" style={{ fontSize: 48, lineHeight: 1 }}>
                        ZOBACZ KTO WYGRAŁ
                    </div>
                    <div className="mt-6">
                        {errorMessage ? (
                            <div className="cc-font-light text-center text-red-500">
                                {errorMessage}
                            </div>
                        ) : (
                            dates.map((dateObj, index) => (
                                <Link to={`/highscores/${dateObj.apiDate}`} key={index} className="flex items-center justify-between bg-white border border-black p-1 mb-3 cc-button-secondary">
                                    <span className="cc-font-bold" style={{ fontSize: '28px' }}>{dateObj.displayDate}</span>
                                    <img
                                        src="/assets/back arrow.svg"
                                        alt="arrow right"
                                        style={{ width: '24px', height: '24px', transform: 'rotate(180deg)' }}
                                    />
                                </Link>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HighscoresListPage;
