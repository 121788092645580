import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getAssetPath } from '../utils';
import CustomModal from './CustomModal';
import { GlobalContext } from '../GlobalContext';

function GameStatusPage() {
    const navigate = useNavigate();
    const { viewMode } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gameData, setGameData] = useState(null); // Store fetched game data
    const { receivedData } = useContext(GlobalContext); // Get receivedData from context
    const [errorMessage, setErrorMessage] = useState(null); // State to handle error messages

    // Fetch game data from `/game/current`
    useEffect(() => {
        const fetchGameData = async () => {
            if (viewMode === 'before-game') return; // Skip fetching game data if viewMode is "before-game"
            
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/game/current`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    if (response.status === 404) {
                        setErrorMessage('Gra na dziś nie została znaleziona.');
                        navigate('/game-status/before-game'); // Redirect to "before-game" if no game found
                    } else if (response.status === 403) {
                        setErrorMessage('Gra jeszcze się nie rozpoczęła.');
                        navigate('/game-status/before-game'); // Redirect if the game has not started
                    } else {
                        throw new Error('Błąd podczas pobierania danych o grze.');
                    }
                    return;
                }

                const data = await response.json();
                setGameData(data); // Store the game data in state
            } catch (error) {
                console.error('Error fetching game data:', error);
                setErrorMessage(error.message);
            }
        };

        fetchGameData();
    }, [navigate]);

    // Function to format completion_time from milliseconds to MM:SS
    const formatCompletionTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleStartClick = () => {
        if (viewMode === 'start-game') {
            setIsModalOpen(true);
        }
    };

    const handleCheckScoreClick = () => {
        const currentDate = new Date().toISOString().split('T')[0]; // Gets the current date in YYYY-MM-DD format
        navigate(`/check-result/${currentDate}`);
    };

    const renderContent = () => {
        if (viewMode !== 'before-game' && !gameData) {
            return null; // Wait for gameData to be loaded
        }

        const reward = gameData !== null ? gameData.reward : null; // Use reward_url from game data

        switch (viewMode) {
            case 'before-game':
                return (
                    <>
                        {/* Bubbles */}
                        <img
                            src='/assets/bubbles 1.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', bottom: '25px', left: '150px', transform: 'scale(1.5)' }}
                        />
                        <img
                            src='/assets/bubbles 2.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', top: '250px', right: '40px', transform: 'scale(1.1)' }}
                        />
                        <img
                            src={getAssetPath('assets/cc background heart.svg')}
                            className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
                            style={{ width: '956px', zIndex: 0 }}
                        />
                        <img
                            src={getAssetPath('assets/cc logo top.svg')}
                            className="w-full w-[100%]"
                            style={{ transform: 'scale(1.25)', marginTop: '20px' }}
                        />
                        <img
                            src={getAssetPath('assets/cc besties forever.svg')}
                            alt="BESTIES FOR A LIMITED TIME"
                            className="w-full mt-10"
                            style={{ transform: 'scale(1.25)' }}
                        />
                        <div className="cc-font-bold" style={{ fontSize: '36px' }}>Gra się jeszcze nie zaczęła.</div>

                        <Link to="https://cokeurl.com/F02F87" className="flex inline-block relative cc-button-primary w-full cc-font-bold text-center mt-6" style={{ paddingTop: '0px' }}>
                            <img
                                src={getAssetPath('assets/coca-cola app 2.svg')}
                                alt="Coca Cola & Oreo"
                                className="mr-4 inline-block"
                                style={{ width: '25.45px', height: '34.2px', marginTop: '12px' }}
                            />
                            <span className='inline cc-font-light text-left' style={{ fontSize: '12px', lineHeight: '1rem', marginTop: '12px' }}>
                                Ściągnij Coke App i sprawdź kiedy możesz zagrać.
                            </span>
                        </Link>
                    </>
                );
            case 'start-game':
                return (
                    <div className='relative'>
                        {/* Bubbles */}
                        <img
                            src='/assets/bubbles 4.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', top: '90px', left: '-15px', transform: 'scale(1) rotate(-70deg)' }}
                        />
                        <img
                            src='/assets/bubble 1.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', top: '30px', left: '2px', transform: 'scale(0.25) rotate(-70deg)' }}
                        />
                        <img
                            src='/assets/bubbles 3.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', bottom: '-35px', right: '8px', transform: 'scale(1) rotate(0deg)' }}
                        />

                        <div style={{ height: '50px' }}></div>

                        <img src={getAssetPath('assets/heart 5.svg')} className="absolute w-full w-[100%]" style={{ transform: 'scaleX(1.34) scaleY(1.34)', marginTop: '136px' }} />

                        <img
                            src={reward.reward_url}
                            className="absolute w-full w-[100%]"
                            style={{ marginTop: '115px', transform: 'scale(1.22)' }}
                        />

                        <div className="cc-font-bold mt-2" style={{ fontSize: '38px' }}>GRA SIĘ JUŻ ROZPOCZĘŁA!</div>
                        <div className="cc-font-bold mt-2" style={{ fontSize: '34px' }}>Dzisiaj grasz o:</div>

                        <div style={{ height: '267px' }}></div>

                        <div className='cc-font-bold relative' style={{ fontSize: '24px', zIndex: '1', lineHeight: '90%' }}>
                            {reward.text_1}
                        </div>
                        <div className='cc-font-bold relative' style={{ fontSize: '36px', zIndex: '1' }}>
                            {reward.text_2}
                        </div>

                        <button onClick={handleStartClick} className="relative block cc-button-secondary w-full cc-font-bold text-center" style={{ fontSize: '36px', bottom: '-70px', width: '182px', left: '50px', padding: '8px 24px' }}>
                            START
                        </button>
                    </div>
                );
            case 'game-result':
                const completionTime = receivedData?.timeCompleted || 0;
                const formattedTime = formatCompletionTime(completionTime);
                const isCompleted = receivedData?.isCompleted;

                return (
                    <div className='relative'>
                        {/* Bubbles */}
                        <img
                            src='/assets/bubbles 1.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', bottom: '165px', left: '20px', transform: 'scale(1.25) ' }}
                        />
                        <img
                            src='/assets/bubbles 3.svg'
                            alt=""
                            className="absolute z-0"
                            style={{ width: '30px', height: 'auto', top: '260px', right: '25px', transform: 'scale(1.1) ' }}
                        />

                        <div style={{ height: '50px' }}></div>

                        <img src={getAssetPath('assets/heart 4.svg')} className="absolute w-full w-[100%]" style={{ marginTop: '0px', transform: 'scale(1.05)' }} />

                        <img
                            src={reward.ranking_url}
                            className="absolute w-full w-[100%]"
                            style={{ marginTop: '-50px', transform: 'scale(1.1)' }}
                        />

                        <div style={{ marginTop: '280px' }}>
                            {/* Conditionally render different divs based on whether the game is completed */}
                            { isCompleted ? (
                                <div style={{marginTop: '300px'}}>
                                    {/* Divs for completed game */}
                                    <div className="cc-font-bold mt-64" style={{ fontSize: '67px', lineHeight: '1' }}>
                                        GRATULACJE!
                                    </div>
                                    <div className="cc-font-bold" style={{ fontSize: '34px', lineHeight: '1' }}>
                                        Twój wynik to:
                                    </div>
                                    <div className="cc-font-bold mb-2" style={{ fontSize: '88px', lineHeight: '1' }}>
                                        {formattedTime}
                                    </div>
                                    <div style={{ height: '55px' }}>

                                    </div>
                                </div>
                            ) : (
                                <>
                                    {/* Divs for incomplete game */}
                                    <div style={{ height: '40px' }}>

                                    </div>
                                    <div className="cc-font-bold" style={{ fontSize: '48px', lineHeight: '1' }}>
                                        Powodzenia następnym razem
                                    </div>
                                    <div style={{ height: '135px' }}>

                                    </div>
                                </>
                            )}
                        </div>

                        <button onClick={handleCheckScoreClick} className="relative block cc-button-secondary w-full cc-font-bold text-center" style={{ fontSize: '36px', bottom: '0px', width: '284px', left: '0px', padding: '8px' }}>
                            SPRAWDŹ SWÓJ WYNIK
                        </button>

                        <Link to="/" className="relative block cc-button-secondary w-full cc-font-bold text-center" style={{ fontSize: '36px', bottom: '-5px', width: '284px', left: '0px', padding: '8px' }}>
                            ZAGRAJ JESZCZE RAZ
                        </Link>
                    </div>
                );
            default:
                return <p className="text-white">Invalid view mode</p>;
        }
    };

    return (
        <div className='relative'>
            {/* black overlay background */}
            <div className="fixed w-full h-full bg-black"></div>

            {/* Content */}
            <div className="relative flex items-center justify-center min-h-screen mb-2">
                <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '341px', zIndex: 0 }}>
                    <Link to="/menu" className='cc-button-secondary absolute z-10 top-6 flex items-center justify-center text-white text-xl font-bold mr-2 cc-font-bold' style={{ width: '121px' }}>
                        <img
                            src={getAssetPath('assets/cc logo.svg')}
                            alt="Coca Cola & Oreo"
                            className="mr-2" style={{ width: '35px' }}
                        />
                        MENU
                    </Link>
                    <div
                        className={`absolute text-center cc-button-secondary ${viewMode === 'game-result' ? 'top-20' : 'top-28'}`}
                        style={{ width: '341px', height: viewMode === 'game-result' ? '644px' : '573px', borderRadius: '16px' }}>
                        {renderContent()}
                    </div>
                </div>

                {/* Modal Component */}
                <CustomModal
                    isOpen={isModalOpen}
                    onRequestClose={() => { setIsModalOpen(false); navigate('/game'); }}
                    contentLabel="Upewnij się, że masz dobre połączenie z internetem"
                    iconSrc="/assets/alert 1.svg"  // Replace with actual path to your icon
                    description="Upewnij się, że masz dobre połączenie z internetem"
                    buttonText="START"
                />
            </div>
        </div>
    );
}

export default GameStatusPage;
