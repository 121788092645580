import React from 'react';
import { Link } from 'react-router-dom';
import { getAssetPath } from '../utils';

function TermsPage() {
    return (
        <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
            <img
                src={getAssetPath('assets/bubbles 6.svg')}
                alt=""
                className="absolute z-0"
                style={{ width: '30px', height: 'auto', top: '35px', right: '60px', transform: 'scale(1.25)' }}
            />
            <img
                src={getAssetPath('assets/bubbles 7.svg')}
                alt=""
                className="absolute z-0"
                style={{ width: '30px', height: 'auto', top: '120px', left: '60px', transform: 'scale(1)' }}
            />
            <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
                {/* Updated MENU button */}
                <Link to="/menu" className="cc-button-secondary absolute z-10 top-6 left-6 flex items-center justify-center text-black text-xl font-bold mr-2 cc-font-bold" style={{ width: '121px' }}>
                    <img
                        src={getAssetPath('assets/cc logo.svg')}
                        alt="Coca Cola & Oreo"
                        className="mr-2"
                        style={{ width: '35px' }}
                    />
                    MENU
                </Link>

                {/* Title */}
                <div className="max-w-xs relative mx-auto px-4 mt-28">
                    <div className="text-2xl text-center cc-font-bold mt-2" style={{ fontSize: 48 }}>
                        REGULAMIN
                    </div>
                </div>

                {/* Main content */}
                <div className="mt-6 px-8">
                    <div className="cc-font-bold text-center">
                        §1
                    </div>
                    <div className="cc-font-bold text-center">
                        POSTANOWIENIA OGÓLNE
                    </div>

                    {/* Content from PDF */}
                    <div className="text-left mt-6">
                        <ol className="list-decimal pl-6 space-y-2">
                            <li className="leading-tight">
                                Konkurs będzie prowadzony pod nazwą „COKE CREATIONS – W ŻABCE” (zwany dalej „Konkursem”).
                            </li>
                            <li className="leading-tight">
                                Organizatorem Konkursu jest „Agencja Reklamowa Fortis” Sp. Jawna, z siedzibą w Warszawie przy ul. Gawińskiego 7, wpisana do Rejestru Przedsiębiorców w Krajowym Rejestrze Sądowym prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, nr KRS 0000080471, NIP: 5240304216 („Organizator”).
                            </li>
                            <li className="leading-tight">
                                Konkurs organizowany jest na zlecenie Coca-Cola HBC Polska sp. z o.o. z siedzibą w Warszawie (02-092), przy ul. Żwirki i Wigury 16, wpisaną do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Warszawie, nr KRS: 0000015664, NIP: 524-21-06-963 („CCHBC”).
                            </li>
                            <li className="leading-tight">
                                Konkurs zostanie przeprowadzony w sklepach sieci Żabka na terenie Polski.
                            </li>
                            <li className="leading-tight">
                                Udział w konkursie „COKE CREATIONS – W ŻABCE” możliwy jest tylko na urządzeniach mobilnych z systemem Android 9 (i wyższym) oraz iOS (12 i wyższym) wyposażonych w żyroskop, akcelerometr oraz z stabilnym połączeniem do sieci Internet.
                            </li>
                            <li className="leading-tight">
                                Konkurs odbywa się w terminie od 11.09.2024 do 24.09.2024 roku.
                            </li>
                            <li className="leading-tight">
                                Uczestnikiem Konkursu może być każda osoba fizyczna zamieszkała na terenie Polski, dokonująca zakupu w sklepach sieci Żabka prowadzących sprzedaż produktów objętych Konkursem na terenie Polski, będąca konsumentem w rozumieniu art. 22¹ kodeksu cywilnego (zwany dalej „Uczestnikiem”).
                            </li>
                            <li className="leading-tight">
                                Udział w konkursie jest dobrowolny.
                            </li>
                            <li className="leading-tight">
                                W Konkursie udział bierze napój Coca-Cola Creations o pojemności 250 ml. w puszce dystrybuowane przez Coca-Cola HBC Polska sp. z o.o. (zwane dalej: Produktami Konkursowymi).
                            </li>
                            <li className="leading-tight">
                                W Konkursie nie mogą brać udziału pracownicy Organizatora, pracownicy innych podmiotów związanych z organizowaniem i przebiegiem Konkursu, a także ich małżonkowie i krewni w linii prostej.
                            </li>
                            <li className="leading-tight">
                                Zakup Produktów konkursowych przed 11.09.2024 lub po terminie 24.09.2024 roku nie uprawnia do wzięcia udziału w Konkursie.
                            </li>
                            <li className="leading-tight">
                                Treść niniejszego regulaminu będzie udostępniona w okresie trwania konkursu pod linkiem theARworlds.com.
                            </li>
                        </ol>
                    </div>

                    {/* Section 2 */}
                    <div className="cc-font-bold text-center mt-6">
                        §2
                    </div>
                    <div className="cc-font-bold text-center mb-6">
                        WARUNKI UCZESTNICTWA W KONKURSIE
                    </div>
                    <ol className="list-decimal pl-6 space-y-2">
                        <li className="leading-tight">
                            Aby wziąć udział w Konkursie należy:
                            <ul className="list-disc pl-6 space-y-2 mt-2">
                                <li>W terminie od 11.09.2024 r. do 24.09.2024 r. dokonać zakupu napoju Coca-Cola Creations dostępnego w aktualnej sprzedaży w sklepach sieci Żabka rejestrując zakup w aplikacji Żappka.</li>
                                <li>Formularz zgłoszeniowy do konkursu dostępny będzie w aplikacji Żappka pod linkiem theARworlds.com Strona wyświetli formularz konkursowy.</li>
                                <li>Zarejestrować się za pomocą formularza zgłoszeniowego w terminie od 11.09.2024 r. do 24.09.2024 r. theARworlds.com podając: imię, nazwisko nr telefonu kontaktowego oraz mail.</li>
                                <li>Rozwiązać zadanie konkursowe polegające na jak najszybszym złożeniu rozsypanych puzzli w przestrzeni AR.</li>
                                <li>Na każdy dzień Organizator przygotował dla Uczestników zadanie konkursowe. Puzzle będzie można złożyć w bramkach czasowych ustalonych specjalnie na każdy dzień Konkursu.</li>
                                <p className="mt-4">Harmonogram gier:</p>

                                {/* Table for game times */}
                                <table className="w-full text-left mt-4 border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="border px-2 py-1">Dzień</th>
                                            <th className="border px-2 py-1">Godzina Startu Gry</th>
                                            <th className="border px-2 py-1">Godzina Zakończenia Gry</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border px-2 py-1">11.09.2024</td>
                                            <td className="border px-2 py-1">06:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">12.09.2024</td>
                                            <td className="border px-2 py-1">09:30</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">13.09.2024</td>
                                            <td className="border px-2 py-1">12:30</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">14.09.2024</td>
                                            <td className="border px-2 py-1">11:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">15.09.2024</td>
                                            <td className="border px-2 py-1">14:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">16.09.2024</td>
                                            <td className="border px-2 py-1">10:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">17.09.2024</td>
                                            <td className="border px-2 py-1">08:30</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">18.09.2024</td>
                                            <td className="border px-2 py-1">12:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">19.09.2024</td>
                                            <td className="border px-2 py-1">07:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">20.09.2024</td>
                                            <td className="border px-2 py-1">17:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">21.09.2024</td>
                                            <td className="border px-2 py-1">09:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">22.09.2024</td>
                                            <td className="border px-2 py-1">10:30</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">23.09.2024</td>
                                            <td className="border px-2 py-1">13:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                        <tr>
                                            <td className="border px-2 py-1">24.09.2024</td>
                                            <td className="border px-2 py-1">06:00</td>
                                            <td className="border px-2 py-1">23:59</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <li>Informacja o bramkach czasowych dostępna będzie pod linkiem theARworlds.com oraz w aplikacji Coca-Cola dostępnej do pobrania w GooglePlay oraz AppStore </li>
                                <li>Wyniki uzyskanych czasów każdej sesji Uczestnicy będą mogli zobaczyć na swoim koncie pod linkiem theARworlds.com </li>
                            </ul>
                        </li>
                        <li className="leading-tight">
                            Nabycie dowolnego Produktu Konkursowego w terminie od 11.09.2024 do 24.09.2024 roku i otrzymanie KODU upoważnia do udziału tylko w jednej grze. Każdy Uczestnik może wziąć udział w Konkursie w terminie od 11.09.2024 r. do 24.09.2024 roku dowolną ilość razy pod warunkiem, że ilość dokonanych prób odpowiada ilości zakupionych Produktów Konkursowych zgodnie z §2 pkt 1. W przypadku dokonania zakupu więcej niż jednego Produktu Konkursowego Uczestnik uprawniony jest do dokonania odpowiedniej ilości prób zgodnie z zasadą, że do wzięcia udziału w konkursie uprawnia dokonanie zakupu minimum jednego Produktu Konkursowego zgodnie z pkt 1 tego paragrafu - tzn. w przypadku zakupu np. 3 napojów Coca-Cola Creations na jednym paragonie Uczestnik otrzymuje 3 kody umożliwiające wzięcie udziału w Konkursie.
                        </li>
                        <li className="leading-tight">
                            W Konkursie nie będą uwzględniane zgłoszenia niespełniające warunków wymienionych w pkt 1-2 tego paragrafu.
                        </li>
                    </ol>

                    {/* Section 3 */}
                    <div className="cc-font-bold text-center mt-6">
                        §3
                    </div>
                    <div className="cc-font-bold text-center mb-6">
                        NAGRODY I ZASADY ICH PRZYZNAWANIA
                    </div>
                    <ol className="list-decimal pl-6 space-y-2">
                        <li className="leading-tight">
                            W Konkursie przewidziane zostały nagrody. Pięciu (5) Uczestników, którzy uzyskają najlepszy czas w zadaniu konkursowym z każdego dnia wygrywają nagrody. Nagrody w Konkursie zostały podzielone na każdy dzień:
                            <table className="w-full text-left mt-4 border-collapse">
                                <thead>
                                    <tr>
                                        <th className="border px-2 py-1">Dzień</th>
                                        <th className="border px-2 py-1">Nagrody</th>
                                        <th className="border px-2 py-1">Wartość</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border px-2 py-1">11.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Eventim</td>
                                        <td className="border px-2 py-1">1000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">12.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Slowhop</td>
                                        <td className="border px-2 py-1">2000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">13.09.2024</td>
                                        <td className="border px-2 py-1">5x Polaroid aparat + wkłady</td>
                                        <td className="border px-2 py-1">530 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">14.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher na Longboard</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">15.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Empik</td>
                                        <td className="border px-2 py-1">1000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">16.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Snipes</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">17.09.2024</td>
                                        <td className="border px-2 py-1">5x Konsola Nintendo OLED</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">18.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Slowhop</td>
                                        <td className="border px-2 py-1">2000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">19.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher na Longboard</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">20.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Snipes</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">21.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Empik</td>
                                        <td className="border px-2 py-1">1000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">22.09.2024</td>
                                        <td className="border px-2 py-1">5x Polaroid aparat + wkłady</td>
                                        <td className="border px-2 py-1">530 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">23.09.2024</td>
                                        <td className="border px-2 py-1">5x Voucher Eventim</td>
                                        <td className="border px-2 py-1">1000 zł brutto</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-2 py-1">24.09.2024</td>
                                        <td className="border px-2 py-1">5x Konsola Nintendo OLED</td>
                                        <td className="border px-2 py-1">1500 zł brutto</td>
                                    </tr>
                                </tbody>
                            </table>

                            <p className='mt-2'> Do każdej z nagród dodana będzie dodatkowa nagroda pieniężna w wysokości 10% wartości nagrody.</p>
                            <p className='mt-2'> Dodatkowe nagrody pieniężne będące częścią ww. nagród zostaną przeznaczone na zapłatę stosownego podatku od nagrody w Konkursach (art. 30 ust. 1 pkt 2 ustawy o podatku dochodowym od osób fizycznych (Ustawa z dnia 26 lipca 1991 r.; tekst jednolity Dz.U. z 2000 r. nr 14, poz 176, z późn. zm.), Organizator potrąci te kwoty przy wydaniu nagród.</p>
                        </li>
                        <li className="leading-tight">
                            Wszystkie prawidłowe zgłoszenia konkursowe dokonane od 11.09.2024 roku do 24.09.2024 roku będą brały udział w konkursie.
                        </li>
                        <li className="leading-tight">
                            Nad przebiegiem Konkursu czuwać będzie Komisja Konkursowa składająca się z 3 osób.
                        </li>
                        <li className="leading-tight">
                            Posiedzenia Komisji Konkursowej odbywać się będą w dniach od 11.09.2024 do 25.09.2024 roku.
                        </li>
                        <li className="leading-tight">
                            Do zadań Komisji Konkursowej należy:
                            <ul className="list-disc pl-6 space-y-2 mt-2">
                                <li>
                                    zapewnienie uczestnictwa w konkursie wszystkim prawidłowym zgłoszeniom,
                                </li>
                                <li>
                                    rozstrzygnięcie konkursu zgodnie z postanowieniami niniejszego regulaminu oraz zasadami pełnej rzetelności przeprowadzonych czynności,
                                </li>
                                <li>
                                    podpisanie listy osób – laureatów konkursu, które otrzymały prawo do nagrody,
                                </li>
                                <li>
                                    prowadzenie postępowań reklamacyjnych.
                                </li>
                            </ul>
                        </li>
                        <li className="leading-tight">
                            Weryfikacja prawa do nagrody będzie prowadzona w następujący sposób:
                            <ul className="list-disc pl-6 space-y-2 mt-2">
                                <li>
                                    W ciągu 4 dni od daty posiedzenia komisji, Organizator Konkursu zadzwoni do zwycięzcy lub wyśle wiadomość SMS na numer, podany w zgłoszeniu lub email.
                                </li>
                                <li>
                                    Uczestnik w rozmowie telefonicznej, SMS-ie lub emailu zostanie powiadomiony o wyniku konkursu i poproszony o potwierdzenie uczestnictwa w konkursie oraz podanie swoich danych osobowych koniecznych do wydania nagrody w ciągu 3 dni od otrzymania powiadomienia.
                                </li>
                                <li>
                                    W przypadku kontaktu telefonicznego Organizator podejmie trzy próby połączenia telefonicznego z Uczestnikiem jednego dnia w godzinach od 9:00 do 17:00. Przez nieudaną próbę połączenia z Uczestnikiem rozumie się: nieodebranie połączenia przez co najmniej 5 sygnałów, włączenie się poczty głosowej, brak możliwości połączenia z użytkownikiem telefonu (np. przebywanie poza zasięgiem, wyłączony aparat telefoniczny).
                                </li>
                                <li>
                                    W przypadku, gdy Laureat do nagrody nie spełni warunków określonych w pkt 6 b lub c, wówczas nastąpi weryfikacja prawa do nagrody uczestnika będącego kolejnym na liście. Uczestnik ten zostanie powiadomiony o ewentualnej wygranej za pomocą SMS lub telefonicznie w ciągu 2 dni liczonych od:
                                    <ul className="list-decimal pl-6 space-y-2 mt-2">
                                        <li>
                                            dnia, w którym nie powiodła się ostatnia, trzecia próba połączenia z poprzednio weryfikowanym Uczestnikiem,
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Weryfikacja Uczestników rezerwowych będzie przebiegała z procedurą określoną w powyższych punktach do czasu przyznania prawa do nagrody lub zakończenia listy rezerwowej.
                                </li>
                            </ul>
                        </li>
                        <li className="leading-tight">
                            Uczestnik traci prawo do nagrody, gdy:
                            <ul className="list-disc pl-6 space-y-2 mt-2">
                                <li>
                                    nie dopełni w terminie któregoś z warunków Regulaminu; lub
                                </li>
                                <li>
                                    odmówi podania swoich danych koniecznych do wydania nagrody;
                                </li>
                            </ul>
                        </li>
                        <li className="leading-tight">
                            Komisja sporządzi protokół z podaniem wyników Konkursu. Uczestnik Konkursu może zapoznać się z protokołem na swoje żądanie w siedzibie Organizatora.
                        </li>
                        <li className="leading-tight">
                            Organizator nie ponosi odpowiedzialności za działania lub zaniechania, które wystąpiły w związku z siłą wyższą. Za siłę wyższą będzie się przyjmować wszelkie okoliczności będące poza kontrolą Organizatora i CCHBC, których Organizator i CCHBC nie mogły przewidzieć ani skutkom, których nie mogły zapobiec przy zachowaniu należytej staranności. Przejawami Siły Wyższej są w szczególności klęski żywiołowe, wojny, rewolucje, zamieszki lub sabotaż, brak działania lub opóźnienia w działaniu władz, strajki oraz lokauty w stopniu w jakim spełniają one powyższe wymagania. Przejawem Siły Wyższej są także działania wywołane w ramach zapobiegania oraz zwalczania choroby zakaźnej u ludzi.
                        </li>
                        <li className="leading-tight">
                            Strony nie będą odpowiedzialne wobec siebie lub uznane za naruszające postanowienia Umowy w związku z niewywiązaniem się ze swoich obowiązków lub opóźnieniem w ich wypełnieniu, jeżeli takie nie wywiązywanie się lub opóźnienie jest wynikiem działania Siły Wyższej.
                        </li>
                    </ol>


                    {/* Section 4 */}
                    <div className="cc-font-bold text-center mt-6">
                        §4
                    </div>
                    <div className="cc-font-bold text-center mb-6">
                        WYDANIE NAGRÓD
                    </div>
                    <ol className="list-decimal pl-6 space-y-2">
                        <li className="leading-tight">
                            Nagrody rzeczowe zostaną przekazane laureatom na adres podany w czasie weryfikacji, wysyłką kurierską najpóźniej do dnia 15.10.2024 roku. Nagrody w postaci Voucherów zostaną wysłane elektronicznie na adresy podane w czasie rejestracji do końca najpóźniej do dnia 15.10.2024 roku.
                        </li>
                        <li className="leading-tight">
                            Nagrody nie podlegają wymianie na ekwiwalent pieniężny ani na inny produkt.
                        </li>
                    </ol>

                    {/* Section 5 */}
                    <div className="cc-font-bold text-center mt-6">
                        §5
                    </div>
                    <div className="cc-font-bold text-center mb-6">
                        OCHRONA DANYCH OSOBOWYCH
                    </div>
                    <ol className="list-decimal pl-6 space-y-2 mb-12">
                        <li className="leading-tight">
                            W celu prawidłowej realizacji Konkursu pod nazwą „COKE CREATIONS – W ŻABCE” niezbędne jest przetwarzanie danych osobowych Uczestników (dalej jako „Dane Osobowe”).
                        </li>
                        <li className="leading-tight">
                            CCHBC i Organizator są współadministratorami Danych Osobowych gromadzonych celu prawidłowej realizacji Konkursu, którzy wspólnie decydują o celach i środkach ich przetwarzania. W związku z powyższym CCHBC i Organizator zawarli porozumienie o współadministrowaniu Danymi Osobowymi, w którym każdy z nich zobowiązał się dokładać wszelkich starań by przetwarzanie danych osobowych realizowane w ramach Konkursu odbywało się zgodnie z przepisami Rozporządzenia UE 2016/679 z dnia 27 kwietnia 2016 r. (ogólne rozporządzenie o ochronie danych), zwanego dalej: „RODO”. Porozumienie określa przy tym odpowiednie zakresy odpowiedzialności CCHBC i Organizatora dotyczącej wypełniania obowiązków wynikających z RODO, a jego zasadnicza treść jest dostępna poprzez kontakt ze współadministratorami w sposób opisany w § 2 ust. 1 Regulaminu.
                        </li>
                        <li className="leading-tight">
                            CCHBC i Organizator powołali w swoich organizacjach Inspektorów Ochrony Danych (IOD), z którymi można kontaktować się na wskazane poniżej dane kontaktowe:
                            <ul className="list-disc pl-6 space-y-2 mt-2">
                                <li>
                                    Kontakt do IOD Organizatora: <a href="mailto:iod@fortis.pl">iod@fortis.pl</a>
                                </li>
                                <li>
                                    Kontakt do IOD CCHBC: <a href="mailto:DataProtectionOffice@cchhellenic.com">DataProtectionOffice@cchhellenic.com</a>
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default TermsPage;
