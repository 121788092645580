import React from 'react';
import { Link } from 'react-router-dom';
import { getAssetPath } from '../utils';

function ContactPage() {
    return (
        <div className="relative flex items-center justify-center min-h-screen bg-white overflow-hidden">
            <img
                src="assets/cc background heart.svg"
                className="absolute bottom-[0] left-1/2 transform -translate-x-1/2"
                style={{ width: '1468px', zIndex: 0 }}
            />
            <div className="relative w-full mx-auto" style={{ minHeight: '100vh', maxWidth: '500px' }}>
                <Link to="/menu" className='cc-button-secondary absolute z-10 top-6 left-6 flex items-center justify-center text-black text-xl font-bold mr-2 cc-font-bold' style={{ width: '121px' }}>
                    <img
                        src={getAssetPath('assets/cc logo.svg')}
                        alt="Coca Cola & Oreo"
                        className="mr-2" style={{ width: '35px' }}
                    />
                    MENU
                </Link>
                <div className="relative">
                    <img
                        src={getAssetPath('assets/heart.svg')}
                        className="w-full"
                        style={{ minHeight: '330px' }}
                    />
                    <img
                        src={getAssetPath('assets/cc hero 2.png')}
                        className="absolute top-[4px] left-1/2 transform -translate-x-1/2 w-[60%]"
                        style={{ marginLeft: '-12px' }}
                    />
                </div>
                <div className='max-w-xs relative mx-auto px-4 mt-12 mb-24'>
                    {/* Bubbles */}
                    <img
                        src='/assets/bubbles 1.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '-20px', left: '40px', transform: 'scale(2) rotate(-15deg)' }}
                    />
                    <img
                        src='/assets/bubbles 3.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '160px', right: '-20px', transform: 'scale(1.1) rotate(-15deg)' }}
                    />
                    {/* <img
                        src='/assets/bubbles 2.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '170px', right: '90px', transform: 'scale(1.1) rotate(-15deg)' }}
                    />
                    <img
                        src='/assets/bubbles 5.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '80px', left: '100px', transform: 'scale(1.25)' }}
                    />
                    <img
                        src='/assets/bubbles 3.svg'
                        alt=""
                        className="absolute z-0"
                        style={{ width: '30px', height: 'auto', top: '555px', right: '10px' }}
                    /> */}
                    <div className="text-2xl text-center cc-font-bold mt-4" style={{ fontSize: 48 }}>
                        KONTAKT
                    </div>
                    <div className='cc-font-bold cc-legal-text mt-6 text-center text-xs '>
                        <p className="text-xs text-center text-gray-700 mt-3 cc-font-light" style={{ fontSize: "14px", lineHeight: '120%' }}>
                            Organizatorem Konkursu jest „Agencja Reklamowa Fortis” Sp. Jawna, z siedzibą w Warszawie przy ul. Gawińskiego 7
                        </p>
                        <p className="text-xs text-center text-gray-700 mt-3 cc-font-light" style={{ fontSize: "14px" }}>
                            Konkurs organizowany jest na zlecenie Coca-Cola HBC Polska sp. z o.o. z siedzibą w Warszawie (02-092), przy ul. Żwirki i Wigury 16
                        </p>
                    </div>
                    <div className="cc-input w-full cc-font-bold mt-6" style={{ fontSize: '28px' }}>
                        (22) 569 61 99
                    </div>
                    <div className="cc-input w-full cc-font-bold mt-2" style={{ fontSize: '28px' }}>
                        creations@fortis.pl
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;